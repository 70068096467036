import { Component } from '@angular/core';
import { FieldForcePosition } from '../models/name-card';

@Component({
    selector: 'app-field-force',
    template: `<app-new-name-card [positions]="positions"></app-new-name-card>`
})
export class FieldForceComponent {
    public positions: FieldForcePosition[];
    constructor() {
        this.positions = Object.keys(FieldForcePosition).map((key: string) => FieldForcePosition[key]);
    }
}
