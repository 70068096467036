import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { Phone } from '../models/phone';

@Component({
  selector: 'app-phone-item',
  templateUrl: './phone-item.component.html',
  styleUrls: ['./phone-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneItemComponent implements OnInit {
  @Input() phone: Phone;

  @Output() selected: EventEmitter<Phone> = new EventEmitter<Phone>();

  constructor() { }

  ngOnInit() {
  }

  onExport(): void {
    this.selected.emit(this.phone);
  }

}
