import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'backgroundImage' })
export class BackgroundImagePipe implements PipeTransform {
    constructor(private sanitization: DomSanitizer) { }

    transform(url: string, defaultUrl: string = ''): any {
        const imageUrl = !!url ? url : defaultUrl;
        return !!imageUrl ? this.sanitization.bypassSecurityTrustStyle(`url(${imageUrl})`) : '';
    }
}
