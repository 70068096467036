<header>
  <div class="title">OPPO</div>
</header>
<!-- <nav>
  <ul>
    <li><a routerLink="/khuyenmai" routerLinkActive="active">Khuyến mãi</a></li>
    <li><a routerLink="/theten" routerLinkActive="active">Thẻ</a></li>
  </ul>
</nav> -->
<main>
  <router-outlet></router-outlet>
</main>

<footer>
  <div>Sử dụng nội bộ cho nhân viên OPPO Việt Nam.</div>
</footer>