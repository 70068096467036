import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PhoneListComponent } from './phone-list/phone-list.component';
import { PromotionListComponent } from './promotion-list/promotion-list.component';
import { PhoneFeatureListComponent } from './phone-feature-list/phone-feature-list.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { PhoneService } from './services/phones.service';
import { PhoneFlyerService } from './services/phone-flyer.service';
import { CaptureScreenshotService } from './services/capture-screenshot.service';
import { PhoneItemComponent } from './phone-item/phone-item.component';
import { MatInputModule } from '@angular/material/input';
import { BackgroundImagePipe } from './pipes/background-image.pipe';
import { NameCardComponent } from './name-card/name-card.component';
import { FileInputComponent } from './file-input/file-input.component';
import { SecureImagePipe } from './pipes/secure-image.pipe';
import {MatSliderModule} from '@angular/material/slider';
import { StoreStaffComponent } from './store-staff/store-staff.component';
import { StoreSupervisorComponent } from './store-supervisor/store-supervisor.component';
import { NewNameCardComponent } from './new-name-card/new-name-card.component';
import { FieldForceComponent } from './field-force/field-force.component';

const materialModules = [
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatSnackBarModule,
  MatRadioModule,
  MatSliderModule
];

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    PhoneListComponent,
    PromotionListComponent,
    PhoneFeatureListComponent,
    PhoneItemComponent,
    BackgroundImagePipe,
    NameCardComponent,
    FileInputComponent,
    SecureImagePipe,
    StoreStaffComponent,
    StoreSupervisorComponent,
    NewNameCardComponent,
    FieldForceComponent,
  ],
  imports: [
    ...materialModules,
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    PhoneService,
    PhoneFlyerService,
    CaptureScreenshotService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
