export interface NameCard {
    name: string;
    position: StaffPosition | SupervisorPosition | FieldForcePosition;
    imageUrl: any;
}

export interface NameCardStyle {
    translateX: number;
    translateY: number;
    scale: number;
    rotate: number;
}

export enum ImageActions {
    Up,
    Right,
    Down,
    Left,
    RotateRight
}

export enum StaffPosition {
    Consultants = 'Nhân viên tư vấn',
    SeniorPromoter = 'Senior Promoter'
}

export enum SupervisorPosition {
    StoreLeader = 'STORE LEADER',
    ProductConsultant = 'PRODUCT CONSULTANT',
}

export enum FieldForcePosition {
    FieldForceExecutive = 'FIELD FORCE EXECUTIVE',
}
