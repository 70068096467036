import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { StoreStaffComponent } from './store-staff/store-staff.component';
import { StoreSupervisorComponent } from './store-supervisor/store-supervisor.component';
import { FieldForceComponent } from './field-force/field-force.component';


const routes: Routes = [
  { path: 'khuyenmai', component: HomePageComponent },
  { path: 'theten', component: StoreStaffComponent },
  { path: 'thetenoes', component: StoreSupervisorComponent },
  { path: 'fieldforce', component: FieldForceComponent },
  { path: '', pathMatch: 'full', redirectTo: 'khuyenmai' },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
