import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Phone } from '../models/phone';
import { PhoneService } from '../services/phones.service';
import { PhoneFlyerService } from '../services/phone-flyer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneListComponent implements OnInit {
  phones$: Observable<Phone[]>;

  @Output() selected: EventEmitter<Phone> = new EventEmitter<Phone>();

  constructor(
    private phoneService: PhoneService,
    private phoneFlyerService: PhoneFlyerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.phones$ = this.phoneService.getAllPhones();
  }

  public selectPhone(phone: Phone): void {
    this.phoneFlyerService.setSelectedPhone(phone);

    this.selected.emit(phone);

    // this.router.navigateByUrl('/print');
  }
}
