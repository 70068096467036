<file-input #avatarInput (fileSelection)="onFileSelected($event)"></file-input>

<div class="form-input hide-on-printing">
  <div>
    <div><label>Vui lòng chọn chức danh</label></div>
    <mat-radio-group color="primary" class="radio-group" [(ngModel)]="nameCard.position">
      <mat-radio-button *ngFor="let position of positions" [value]="position">{{position}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <mat-form-field>
      <mat-label>
        <mat-icon>account_box</mat-icon> Tên nhân viên
      </mat-label>
      <input matInput [(ngModel)]="nameCard.name">
    </mat-form-field>
  </div>
</div>

<div id="name-card-print" [class.exporting]="exporting">
  <div class="name-card new-name-card">
    <header>
      <div class="logo-wrapper">
        <div class="logo">
          <img src="assets/logo/OPPO_LOGO_2024.svg">
        </div>
      </div>
    </header>
    <section class="main">
      <div class="avatar-frame new-avatar-frame" [style]="!!nameCard.imageUrl ? 'border: none;' : ''">
        <img 
          *ngIf="!!nameCard.imageUrl"
          [src]="nameCard.imageUrl"
          [ngStyle]="customStyle"
        />
      </div>
    </section>
    <footer class="no-border full">
      <div>
        <div class="name"><strong>{{nameCard.name}}</strong></div>
        <div class="position">{{nameCard.position}}</div>
      </div>
    </footer>
  </div>

  <div class="name-card magic new-name-card">
    <header>
      <div class="logo-wrapper">
        <div class="logo">
          <img src="assets/logo/OPPO_LOGO_2024.svg">
        </div>
      </div>
    </header>
    <section class="main">
      <div class="avatar-frame new-avatar-frame" [style]="!!nameCard.imageUrl ? 'border: none;' : ''">
        <img 
          *ngIf="!!nameCard.imageUrl"
          [src]="nameCard.imageUrl"
          [ngStyle]="customStyle"
        />
      </div>
    </section>
    <footer class="no-border full">
      <div>
        <div class="name"><strong>{{nameCard.name}}</strong></div>
        <div class="position">{{nameCard.position}}</div>
      </div>
    </footer>
  </div>
  <!-- <div class="backdrop">
      <img src="/assets/logo/backdrop.jpg" alt="">
  </div> -->
</div>

<div class="image-editor hide-on-printing">
  <div>
    <button class="btn-action" mat-flat-button color="primary" (click)="uploadImage()">Chọn ảnh</button>
    <button class="btn-action" mat-flat-button color="primary" (click)="export()">Xuất ảnh</button>
    <button class="btn-action" mat-flat-button color="primary" (click)="print()">In thẻ</button>
  </div>
  <div>
    <label>Kích thước ảnh</label>
    <mat-slider color="primary" [step]="0.1" [min]="1" [max]="10" [(ngModel)]="nameCardStyle.scale"></mat-slider>
  </div>
  <div class="grid">
    <div class="cell"></div>
    <div class="cell">
        <button mat-mini-fab color="primary" (click)="move(actions.Up)">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <div class="cell"></div>
    <div class="cell">
        <button mat-mini-fab color="primary" (click)="move(actions.Left)">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
    </div>
    <div class="cell">
        <button mat-mini-fab color="primary" (click)="move(actions.RotateRight)">
            <mat-icon>rotate_right</mat-icon>
        </button>
    </div>
    <div class="cell">
        <button mat-mini-fab color="primary" (click)="move(actions.Right)">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
    <div class="cell"></div>
    <div class="cell">
        <button mat-mini-fab color="primary" (click)="move(actions.Down)">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
    </div>
    <div class="cell"></div>
  </div>
</div>
