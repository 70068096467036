import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Phone } from '../models/phone';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class PhoneService {
    /**
     *
     */
    constructor(private http: HttpClient) {
    }

    getAllPhones(): Observable<Phone[]> {
        const version = this.getVersion();
        return this.http.get<Phone[]>(`assets/api/phones.json?v=${version}`).pipe(map(phones => phones.map(phone => ({
            ...phone,
            picture: `assets/images/${phone.picture}?v=${version}`,
            promotion: `assets/images/${phone.promotion}?v=${version}`,
        }))));
    }

    private getVersion(): string {
        const today = new Date();
        return `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}${today.getHours()}${today.getMinutes()}`;
    }
}
