import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NameCard, StaffPosition, NameCardStyle, ImageActions, SupervisorPosition, FieldForcePosition } from '../models/name-card';
import { PhoneFlyerService } from '../services/phone-flyer.service';
import { FileInputComponent } from '../file-input/file-input.component';
import { CaptureScreenshotService } from '../services/capture-screenshot.service';

@Component({
    selector: 'app-name-card',
    templateUrl: './name-card.component.html',
    styleUrls: ['./name-card.component.scss']
})
export class NameCardComponent implements OnInit {
    @ViewChild('avatarInput', { static: false }) fileInput: FileInputComponent;
    @Input() positions: StaffPosition[] | SupervisorPosition[] | FieldForcePosition[];

    nameCard: NameCard;
    nameCardStyle: NameCardStyle;
    actions = ImageActions;
    exporting: boolean;

    constructor(
        private phoneFlyerService: PhoneFlyerService,
        private captureService: CaptureScreenshotService,
    ) { }

    ngOnInit(): void {
        const salePerson = this.phoneFlyerService.getSalePersonInfo();
        this.nameCard = {
            name: salePerson.name,
            imageUrl: '',
            position: this.positions[0]
        };
        this.nameCardStyle = {
            translateX: 0,
            translateY: 0,
            scale: 1,
            rotate: 0
        };
    }

    public onFileSelected(files: File[]): void {
        if (!files || files.length === 0) {
            this.nameCard.imageUrl = null;
            return;
        }
        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.nameCard.imageUrl = null;
            return;
        }

        const reader = new FileReader();
        // this.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            this.nameCard.imageUrl = reader.result;
        };
    }

    public uploadImage(): void {
        if (!!this.fileInput) {
            this.fileInput.openFile();
        }
    }

    public move(action: ImageActions): void {
        const step = 10;
        switch (action) {
            case ImageActions.Up:
                this.nameCardStyle.translateY -= step;
                break;
            case ImageActions.Down:
                this.nameCardStyle.translateY += step;
                break;
            case ImageActions.Right:
                this.nameCardStyle.translateX += step;
                break;
            case ImageActions.Left:
                this.nameCardStyle.translateX -= step;
                break;
            case ImageActions.RotateRight:
                this.nameCardStyle.rotate += 45;
                break;
            default:
                break;
        }
    }

    public get customStyle(): any {
        const { translateX, translateY, scale, rotate } = this.nameCardStyle;
        return {
            transform: `translateX(${translateX}px) translateY(${translateY}px) scale(${scale}) rotate(${rotate}deg)`
        };
    }

    public export(): void {
        this.exporting = true;
        setTimeout(() => {
            const node = document.getElementById('name-card-print');
            console.error(node);
            if (!!node) {
                this.captureService.capture(node, `${this.nameCard.name}`);
            }
        }, 500);

        setTimeout(() => {
            this.exporting = false;
        }, 1000);
    }

    public print(): void {
        window.print();
    }
}
