<h1 style="margin-bottom: 5rem;">Welcome to Oppo! Have a nice day</h1>

<!-- <mat-form-field>
  <mat-label><mat-icon>account_box</mat-icon> Tên nhân viên</mat-label>
  <input matInput [(ngModel)]="salePerson.name">
</mat-form-field>
<mat-form-field>
  <mat-label><mat-icon>call</mat-icon> Số điện thoại</mat-label>
  <input matInput [(ngModel)]="salePerson.phone">
</mat-form-field>
<mat-form-field>
  <mat-label>Địa chỉ cửa hàng</mat-label>
  <input matInput [(ngModel)]="salePerson.storeAddress">
</mat-form-field>
<app-phone-list
  (selected)="onExport($event)"
></app-phone-list>

<div id="flyer-print-container" class="print-template" *ngIf="!!selectedPhone">
    <img class="phone-frame" [src]="selectedPhone.promotion" (load)="readyToExport()" />

    <div class="sale-person-info">
        <div class="name">{{salePerson.name}}</div>
        <div class="phone">{{salePerson.phone}}</div>
        <div class="address">{{salePerson.storeAddress}}</div>
    </div>

</div> -->
