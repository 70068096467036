import { Component } from '@angular/core';
import { SupervisorPosition } from '../models/name-card';

@Component({
    selector: 'app-store-supervisor',
    template: `<app-name-card [positions]="positions"></app-name-card>`
})
export class StoreSupervisorComponent {
    public positions: SupervisorPosition[];
    constructor() {
        this.positions = Object.keys(SupervisorPosition).map((key: string) => SupervisorPosition[key]);
    }
}
