import { Component, OnInit } from '@angular/core';
import { PhoneFlyerService } from '../services/phone-flyer.service';
import { SalePerson } from '../models/sale-person';
import { Phone } from '../models/phone';
import { CaptureScreenshotService } from '../services/capture-screenshot.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  selectedPhone: Phone;
  salePerson: SalePerson;

  constructor(
    private phoneFlyerService: PhoneFlyerService,
    private captureService: CaptureScreenshotService,
  ) { }

  ngOnInit(): void {
    this.selectedPhone = this.phoneFlyerService.getSelectedPhone();
    this.salePerson = this.phoneFlyerService.getSalePersonInfo();
  }

  onExport(phone: Phone): void {
    this.selectedPhone = phone;
    this.phoneFlyerService.setSalePersonInfo(this.salePerson);
  }

  readyToExport(): void {
    setTimeout(() => {
      const node = document.getElementById('flyer-print-container');
      if (!!node) {
        this.captureService.capture(node, `${this.selectedPhone.name} - ${this.salePerson.name}`);

        setTimeout(() => {
          this.selectedPhone = null;
        }, 1500);
      }
    }, 500);
  }

}
