import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'file-input',
    template: `<input #importFileInput type="file" style="display:none" (change)="onUpload($event)" [accept]="accept">`,
    styles: [`:host {display: none}`],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent {

    @ViewChild('importFileInput', { static: false }) importFileInput: ElementRef;
    @Input() accept: string;
    @Output() fileSelection = new EventEmitter<File[]>();

    onUpload(event) {
        const files: File[] = [];
        for (let index = 0; index < event.target.files.length; index++) {
            const file = event.target.files.item(index);
            files.push(file);
        }
        this.fileSelection.emit(files);
    }

    openFile() {
        this.importFileInput.nativeElement.value = '';
        this.importFileInput.nativeElement.click();
    }
}
