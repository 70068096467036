import { Injectable } from '@angular/core';
import { Phone } from '../models/phone';
import { SalePerson } from '../models/sale-person';
import { PhoneFlyer } from '../models/phone-flyer';

const SALE_PERSON_STORE_KEY = 'oppo-promotion-sale-person';

@Injectable()
export class PhoneFlyerService {
    private selectedPhone: Phone;
    private salePerson: SalePerson;


    setSelectedPhone(phone: Phone): void {
        this.selectedPhone = phone;
    }

    getSelectedPhone(): Phone {
        return this.selectedPhone;
    }

    setSalePersonInfo(person: SalePerson): void {
        this.salePerson = person;
        localStorage.setItem(SALE_PERSON_STORE_KEY, JSON.stringify(person));
    }

    getSalePersonInfo(): SalePerson {
        if (!this.salePerson) {
            const data = localStorage.getItem(SALE_PERSON_STORE_KEY);
            if (!!data) {
                this.salePerson = JSON.parse(data);
            }
        }

        // fallback default value
        if (!this.salePerson) {
            this.salePerson = {
                name: '',
                phone: '',
                storeAddress: ''
            };
        }

        return this.salePerson;
    }

    generateFlyerInfo(): PhoneFlyer {
        return {
            selectedPhone: this.selectedPhone,
            salePerson: this.salePerson
        };
    }
}
