import { Component } from '@angular/core';
import { StaffPosition } from '../models/name-card';

@Component({
    selector: 'app-store-staff',
    template: `<app-name-card [positions]="positions"></app-name-card>`
})
export class StoreStaffComponent {
    public positions: StaffPosition[];
    constructor() {
        this.positions = Object.keys(StaffPosition).map((key: string) => StaffPosition[key]);
    }
}
