import { Component } from '@angular/core';
import { NameCardComponent } from '../name-card/name-card.component';

@Component({
    selector: 'app-new-name-card',
    templateUrl: './new-name-card.component.html',
    styleUrls: [
        '../name-card/name-card.component.scss',
        './new-name-card.component.scss'
    ]
})
export class NewNameCardComponent extends NameCardComponent { }
