import domtoimage from 'src/app/libs/dom-to-image';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class CaptureScreenshotService {
    capture(node: HTMLElement, name: string): void {
        domtoimage.toBlob(node, {scale: 2.5})
            .then(blob => {
                saveAs(blob, `${name}.png`);
            });
    }
}
